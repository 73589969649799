import AOS from './aos';

document.addEventListener("DOMContentLoaded", function () {

    AOS.init({
        once: true,
    });

    const customSelects = document.querySelectorAll('.custom-select');

    customSelects.forEach((select) => {
        const konuSecimi = new Choices(select, {
            removeItemButton: false,
            placeholder: true,
            searchEnabled: false,
            shouldSort: false
        });
    });

    if (document.getElementById('locationMap')) {
        var map = L.map('locationMap').setView([41.05612260852217, 29.003083281806138], 17);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        L.marker([41.05612260852217, 29.003083281806138]).addTo(map)
            .bindPopup('Polat Boks<br> Beşiktaş, İstanbul.')
            .openPopup();
    }

    if (document.getElementById('productDetail')) {
        var thumbSlider = new Swiper("#productDetailThumbs.swiper", {
            loop: false,
            spaceBetween: 10,
            slidesPerView: 3,
            watchSlidesProgress: true,
            watchOverflow: true,
            slideToClickedSlide: true,
            
        });

        var mainSlider = new Swiper("#productDetail .c-product-detail__main .swiper", {
            loop: false,
            spaceBetween: 10,
            slidesPerView: 1,
            thumbs: {
                swiper: thumbSlider,
            },
            pagination: {
                el: "#productDetail .swiper-pagination",
                clickable: true,
            },
        });

        mainSlider.on("slideChange", function () {
            thumbSlider.slideTo(mainSlider.activeIndex);
        });

        thumbSlider.on("slideChange", function () {
            mainSlider.slideTo(thumbSlider.activeIndex);
        });
    }



    const header = document.querySelector('.o-header');

    if (document.querySelector('.main-wrapper--has-banner') && !(document.querySelector('.c-banner'))) {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 50) {
                header.classList.add('o-header--gray');
            } else {
                header.classList.remove('o-header--gray');
            }
        });
    } else {
        header.classList.add('o-header--gray');
    }

    const accordions = document.querySelectorAll('.accordion');

    accordions.length > 0 && accordions.forEach(accordion => {
        const items = accordion.querySelectorAll('.accordion-item');

        items.forEach((item) => {
            const head = item.querySelector('.accordion-head');

            head.addEventListener('click', function () {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                } else {
                    items.forEach(accItem => accItem.classList.remove('active'));
                    item.classList.add('active');
                }
            });
        });

    });

    const swiper = new Swiper('#main-slider', {
        loop: true,
        speed: 700,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        navigation: {
            prevEl: '#main-slider .c-main-slider__arrow--left',
            nextEl: '#main-slider .c-main-slider__arrow--right',
        },
        pagination: {
            el: '#main-slider .swiper-pagination',
            clickable: true,
        },
    });

    // TABS
    document.querySelectorAll('.tab-area').length > 0 && document.querySelectorAll('.tab-area').forEach(tabArea => {
        const tabs = tabArea.querySelectorAll('.tab-item');
        const contents = tabArea.querySelectorAll('.tab-content');

        tabs.forEach(tab => {
            tab.addEventListener('click', function () {
                const target = this.dataset.tab;

                tabs.forEach(t => t.classList.toggle('active', t === this));
                contents.forEach(content => {
                    content.classList.toggle('active', content.dataset.tab === target);
                });

                if (document.getElementById('profile') && document.querySelector('.o-page__title')) {
                    document.querySelector('.o-page__title').textContent = this.textContent.trim();
                }
            });
        });
    });

    // FORMS
    document.querySelectorAll(".o-form__item--pasword").length > 0 && document.querySelectorAll(".o-form__item--pasword").forEach(passwordWrapper => {
        const passwordInput = passwordWrapper.querySelector("input");
        const eyeIcon = passwordWrapper.querySelector(".password-eye");

        eyeIcon.addEventListener("click", function () {
            if (passwordInput.type === "password") {
                passwordInput.type = "text";
                eyeIcon.style.fill = "black";
            } else {
                passwordInput.type = "password";
                eyeIcon.style.fill = "#e2e2e2";
            }
        });
    });
});